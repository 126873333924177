import {Col, Row} from "react-bootstrap";

const StoreComponent = () => {
    return (
        <footer className="footer-component">
            <Row className="gap-3" style={{
                width: "90%",
            }}>
                <img
                    src={`/images/promed-home.png`}
                    style={{
                        width: "6rem",
                        padding: 0,
                    }}
                    alt={`프로메드 로고`}/>
                <Row className="w-100 p-0">
                    <Col md={"6"} className="p-0">
                        <p>
                            주식회사 하우투약 (HowToYak Inc.) 대표{" "}
                            <span>김경열</span>
                        </p>
                        <p>
                            주소{" "}
                            <span>
                                {/*서울특별시 강남구 테헤란로 520, 14층 네스트 강남(대치동, 삼안빌딩)*/}
                                06237 서울 강남구 테헤란로 22길 32, 4층 (역삼동, 리앤리빌딩)
                            </span>
                        </p>
                        <p>
                            사업자등록번호{" "}
                            <span>774-88-01428{" "}</span>
                            <button
                                onClick={() => {
                                    window.open(
                                        `https://www.ftc.go.kr/bizCommPop.do?wrkr_no=7748801428`,
                                        "_blank"
                                    );
                                }}
                            >
                                사업자정보
                            </button>
                        </p>

                        <p>
                            통신판매업신고{" "}<span>2020-서울강남-00242</span>
                        </p>
                        <p>
                            개인정보보호책임자{" "}
                            <span>김경열(help@howtoyak.com)</span>
                        </p>
                        <p>
                            호스팅사업자{" "}<span>주식회사 하우투약</span>
                        </p>
                    </Col>
                    <Col md={"6"} className="p-0">
                        <Row style={{
                            marginTop: "auto",
                            gap: "5px",
                        }}>
                            <Col md={"6"} className="p-0 w-100">
                                <p>06237 서울 강남구 테헤란로 22길 32, 4층 (역삼동, 리앤리빌딩)</p>
                                <Row>
                                    <Col md={"6"} className="p-0">
                                        <p>T : 1899 - 2879</p>
                                    </Col>
                                    <Col md={"6"} className="p-0">
                                        <p>E : how2yak@howtoyak.com</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={"6"} className="p-0">
                                        <p>채용문의 : recruit@howtoyak.com</p>
                                    </Col>
                                    <Col md={"6"} className="p-0">
                                        <p>제휴제안문의 : biz@howtoyak.com</p>
                                    </Col>
                                    <Col md={"6"} className="p-0">
                                        <p>광고문의 : ad@howtoyak.com</p>
                                    </Col>
                                    <Col md={"6"} className="p-0">
                                        <p>기타문의 : contact@howtoyak.com</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={"6"} className="p-0" style={{
                                width: "fit-content",
                            }}>
                                <p style={{
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }} onClick={() => {
                                    window.open("https://www.dangyoung.com/policy", "_blank");
                                }}>
                                    개인정보처리방침
                                </p>
                                <p style={{
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                }} onClick={() => {
                                    window.open("https://www.dangyoung.com/terms", "_blank");
                                }}>
                                    이용약관
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*<Row className="w-100 p-0">*/}
                {/*    <Col md={"6"} className="p-0">*/}
                {/*        <p>06237 서울 강남구 테헤란로 22길 32, 4층 (역삼동, 리앤리빌딩)</p>*/}
                {/*        <Row>*/}
                {/*            <Col md={"6"} className="p-0"><p>T : 1899 - 2879</p></Col>*/}
                {/*            <Col md={"6"} className="p-0"><p>E : how2yak@howtoyak.com</p></Col>*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*    <Col className="p-0">*/}
                {/*        <Row>*/}
                {/*            <Col md={"6"} className="p-0"><p>채용문의 : recruit@howtoyak.com</p></Col>*/}
                {/*            <Col md={"6"} className="p-0"><p>제휴제안문의 : biz@howtoyak.com</p></Col>*/}
                {/*        </Row>*/}
                {/*        <Row>*/}
                {/*            <Col md={"6"} className="p-0"><p>광고문의 : ad@howtoyak.com</p></Col>*/}
                {/*            <Col md={"6"} className="p-0"><p>기타문의 : contact@howtoyak.com</p></Col>*/}
                {/*        </Row>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

            </Row>
        </footer>
    )
}
export default StoreComponent