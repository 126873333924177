import {Col, Row} from "react-bootstrap";

const BrandComponent = () => {
    return (
        <div className="component-div" id="brand">
            <Row className={`gap-4 gap-md-0 component-padding-top`}>
                <Col md={"6"} className="d-flex align-items-center">
                    <h1>약사가 치밀하게 설계한{"\n"}프리미엄 프로메드</h1>
                </Col>
                <Col md={"6"} className="d-flex align-items-center">
                    <span>약사가 직접 설계하고 배합하는 프로메드는 전문가를 뜻하는 Pro와 의학을 뜻하는 Med의 뜻을 함께 담고 있는 건강기능식품 브랜드입니다. 다양한 고품질의 원료들이 함께 어우러진 한편의 오케스트라 같은 경험을 제공합니다.</span>
                </Col>
            </Row>
            <Row className={`gap-5`}>
                <img
                    className={`w-100 p-0`}
                    src={`/images/promed-brand-introduction.jpg`}
                    alt={`프로메드-브랜드소개`}
                />
                <Row className="gap-4 p-0">
                    <Col md="6" className="p-0 d-flex flex-column gap-4">
                        <img
                            className={`w-100 p-0`}
                            src={`/images/promed-high-content.jpg`}
                            alt={`프로메드-고함량`}
                        />
                        <div className="text-div">
                            <h2>최고의 퍼포먼스를 위한{"\n"}고함량 설계</h2>
                            <span>프로메드는 의사, 약사, 영양사와 같은 전문가들이 모여 모든 제품이 최고의 퍼포먼스를 낼 수 있도록 식약처에게 권고하는 건강기능식품 하루 최대 권장량을 지키며 최고의 퍼포먼스를 낼 수 있는 함량을 연구하고 설계하여 담았습니다.</span>
                        </div>
                    </Col>
                    <Col className="p-0 d-flex flex-column gap-4">
                        <img
                            className={`w-100 p-0`}
                            src={`/images/promed-premium.jpg`}
                            alt={`프로메드-고품질`}
                        />
                        <div className="text-div">
                            <h2>프로들의 끈질긴 선택{"\n"}고품질 원료</h2>
                            <span>프로메드는 주원료, 부원료 하나하나 원산지부터 제조시설까지 빠짐없이 꼼꼼하고 엄격하게 선별하여 더 좋은 원료를 연구하며 고객들에게 고품질의 좋은 원료가 담긴 제품을 선보이기위해 끊임없이 노력합니다.</span>
                        </div>
                    </Col>
                </Row>
            </Row>
        </div>
    )
}
export default BrandComponent