const StoreComponent = () => {
    return (
        <div className="home-component" id="store">
            <img
                style={{
                    minHeight: "280px",
                    width: "100%",
                    objectFit: "cover"
                }}
                className={`w-100 p-0`}
                src={`/images/promed-smartstore.jpg`}
                alt={`프로메드-스마트스토어`}
            />
            <div className="home-title">
                <h3>약사가 설계한{"\n"}프로메드의 치밀함을 만나보세요.</h3>
                <button className="store-btn" onClick={(e) => {
                    e.preventDefault();
                    window.open("https://smartstore.naver.com/almul", "_blank");
                }}>
                    <a href="https://smartstore.naver.com/almul" target="_blank" rel="noreferrer">스토어 바로가기</a>
                </button>
            </div>
        </div>
    )
}
export default StoreComponent