import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Header";
import Home from "./pages/Home";

function App() {
    return (
        <div className="position-relative">
            <Header/>
            <Home/>
        </div>
    );
}

export default App;
