const HomeComponent = () => {
    return (
        <div className="home-component" id="home">
            <img
                style={{
                    minHeight: "280px",
                    width: "100%",
                    objectFit: "cover",
                    height: "auto"
                }}
                src={`/images/promed-main.jpg`}
                alt={`프로메드-메인`}
            />
            <div className="home-title">
                <span>약사가 설계한 치밀함</span>
                <img
                    style={{
                        width: "16rem",
                        height: "auto"
                    }}
                    // src={`${process.env.REACT_APP_HOST}/images/promed-home-logo.png`}
                    src={`/images/promed-home-logo.png`}
                    alt={`프로메드-로고`}
                />
            </div>
        </div>
    )
}
export default HomeComponent