import {Container, Nav, Navbar} from "react-bootstrap";

const Header = () => {
    return (
        <Navbar expand="sm" className="header-component">
            <Container fluid="sm" className="px-0 px-md-2">
                <Navbar.Brand href="#home" className="ps-3 ps-md-0">
                    <img
                        src={`/images/promed-home.png`}
                        style={{
                            width: "6rem",
                        }}
                        alt={`프로메드 로고`}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="promed-navbar-nav" className="me-3 ms-md-0"/>
                <Navbar.Collapse id="promed-navbar-nav" className="w-100 bg-white">
                    <Nav className="w-100 justify-content-end p-3 p-md-0 gap-3">
                        <Nav.Link href="#brand" className="nav-link">브랜드소개</Nav.Link>
                        <Nav.Link href="#products" className="nav-link">제품안내</Nav.Link>
                        <Nav.Link href="#store" className="nav-link">구매하기</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}
export default Header