import {Col, Row} from "react-bootstrap";

const ProductsComponent = () => {
    return (
        <div className="component-div" id="products">
            <Row className={`gap-4 gap-md-0 component-padding-top`}>
                <Col md={"6"} className="d-flex align-items-center">
                    <h2>최고의 제품만을 위한{"\n"}프로들의 고집</h2>
                </Col>
                <Col md={"6"} className="d-flex align-items-center">
                    <span>프로메드는 누구나 건강할 권리가 있다는 이념으로 건강한 라이프 스타일을 선도하기 위해 최고의 품질을 자랑하는 제품 제공을 위한 고품질의 원료와 함량은 포기하지 않고 최소한의 마케팅을 통해 합리적인 가격으로 많은 사람들에게 건강을 공유하기 위해 언제나 노력하고 있습니다.</span>
                </Col>
            </Row>
            <ul className={`gap-5 row p-0`}>
                <img
                    className={`w-100 p-0`}
                    src={`/images/promed-ideology.jpg`}
                    alt={`프로메드-이념`}
                />
                <h3>프로메드 제품 소개</h3>
                <h4>건강기능식품</h4>
                <li className="promed-products row">
                    <Col md={"6"}>
                        <figure>
                            <img
                                className={`w-100 p-0`}
                                src={`/images/products/promed-energysolution.png`}
                                alt={`프로메드-에너지솔루션`}
                            />
                            <figcaption className="a11y-hidden">프로메드 에너지솔루션</figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <span>프리미엄 비타민B군</span>
                        <h3 className="p-0">에너지솔루션</h3>
                        <p>활력을 위해 필요한 신체 에너지 대사를 활성화하는 비타민B군과 충분한 수면을 유도할 수 있도록 스트레스로 인한 신경 안정에 도움을 주는 테아닌이 함께 배합되어 있어
                            신체적, 정신적 피로를 한 번에 관리하실 수 있는 비타민B군입니다.</p>
                        <a href={`https://smartstore.naver.com/almul/products/5598779349`} target={"_blank"}
                           rel="noreferrer">자세히보기</a>
                    </Col>
                </li>
                <li className="promed-products row">
                    <Col md={"6"}>
                        <figure>
                            <img
                                className={`w-100 p-0`}
                                src={`/images/products/promed-eyefresh.png`}
                                alt={`프로메드-아이프레시`}
                            />
                            <figcaption className="a11y-hidden">프로메드 아이프레시</figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <span>눈 건강 트리플케어</span>
                        <h3 className="p-0">아이프레시</h3>
                        <p>눈 관리에 도움이 되는 루테인 1일 권장섭취량인 10mg가 포함돼 노화로 인해 감소할 수 있는 황반색소 밀도를 유지하고 눈 피로를 회복시키는데 탁월하다 알려진
                            아스타잔틴 4mg, 빌베리 80mg가 들어 있으며 야맹증 예방에 도움이 되는 비타민A가 700ugRAE 배합된 올인원 눈 건강을 위한 건강기능식품입니다.</p>
                        <a href={`https://smartstore.naver.com/almul/products/5598773574`} target={"_blank"}
                           rel="noreferrer">자세히보기</a>
                    </Col>
                </li>
                <li className="promed-products row">
                    <Col md={"6"}>
                        <figure>
                            <img
                                className={`w-100 p-0`}
                                src={`/images/products/promed-boostup-milkthistle.png`}
                                alt={`프로메드-부스트업-밀크씨슬`}
                            />
                            <figcaption className="a11y-hidden">프로메드 부스트업 밀크씨슬</figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <span>간 건강을 위한</span>
                        <h3 className="p-0">부스트업 밀크씨슬</h3>
                        <p>간 건강에 도움을 줄 수 있는 프랑스산 밀크씨슬을 식약처 기준 하루 권장량 최대치인 130mg을 가득 담았으며 에너지 생성 및 대사에 필요한 비타민B군 3종과 세포
                            분열과 보호를 위한 아연 셀렌부터 부원료 타우린, L-카르니틴까지 담아 간 건강을 위한 확실한 시너지 부스트업을 도와줍니다.</p>
                        <a href={`https://smartstore.naver.com/almul/products/5598783763`} target={"_blank"}
                           rel="noreferrer">자세히보기</a>
                    </Col>
                </li>
                <h4>건강식품</h4>
                <li className="promed-products row">
                    <Col md={"6"}>
                        <figure>
                            <img
                                className={`w-100 p-0`}
                                src={`/images/products/promed-bakers’yeast.png`}
                                alt={`프로메드-더블랙맥주효모환`}
                            />
                            <figcaption className="a11y-hidden">프로메드 더블랙맥주효모환</figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <span>고순도 고함량 풍성한 영양</span>
                        <h3 className="p-0">더블랙맥주효모환</h3>
                        <p>약사가 아내의 풍성한 영양을 위해 만든 맥주효모로 알코올 및 첨가제, 부형제가 들어있지 않아 남녀노소 안전하게 섭취할 수 있습니다. 단백질과 아미노산이 풍부한
                            리투아니아산 맥주효모만 100% 사용하여 3,500mg 고함량으로 담아낸 제품입니다. 3mm의 작고 동글한 형태의 환으로 목넘김이 부드럽고 편하게 섭취하실 수
                            있습니다.</p>
                        <a href={`https://smartstore.naver.com/almul/products/6688291123`} target={"_blank"}
                           rel="noreferrer">자세히보기</a>
                    </Col>
                </li>
                <li className="promed-products row">
                    <Col md={"6"}>
                        <figure>
                            <img
                                className={`w-100 p-0`}
                                src={`/images/products/promed-boostup-arginine.png`}
                                alt={`프로메드-부스트업-아르기닌-6000`}
                            />
                            <figcaption className="a11y-hidden">프로메드 부스트업 아르기닌 6000</figcaption>
                        </figure>
                    </Col>
                    <Col>
                        <span>퍼포먼스 부스팅 3대 조합</span>
                        <h3 className="p-0">부스트업 아르기닌 6000</h3>
                        <p>프로메드 부스트업아르기닌6000은 미국 국립 과학센터 NCSF의 연구 결과로 밝혀진 부작용 없이 안전하게 섭취할 수 있는 아르기닌 함량을 준수하여 6,000mg
                            고함량으로 설계된 제품입니다. 최상의 컨디션을 위해 아르기닌의 흡수율을 높여주는 오르니틴과 아연, 타우린이 함께 들어 있어 피로회복, 건강회복에 도움을 줄 수
                            있습니다.</p>
                        <a href={`https://smartstore.naver.com/almul/products/6941489251`} target={"_blank"}
                           rel="noreferrer">자세히보기</a>
                    </Col>
                </li>
            </ul>
        </div>
    )
}
export default ProductsComponent