import HomeComponent from "../components/HomeComponent";
import BrandComponent from "../components/BrandComponent";
import ProductsComponent from "../components/ProductsComponent";
import StoreComponent from "../components/StoreComponent";
import FooterComponent from "../components/FooterComponent";

const Home = () => {
    return (
        <div className="position-relative d-flex flex-column">
            <HomeComponent/>
            <BrandComponent/>
            <img
                className={`w-100 p-0`}
                src={`/images/promed-description.jpg`}
                alt={`프로메드-설명`}
            />
            <ProductsComponent/>
            <StoreComponent/>
            <FooterComponent/>
        </div>
    )
}
export default Home